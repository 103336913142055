import { Close } from '@mui/icons-material';
import { Box, Stack, IconButton, Typography, Paper, Divider } from '@mui/material';
import { CustomModel } from "@/components/Elements/custom-modalAfterLogin";
import lostLogo from "@/assets/lostLoogo.svg";
import closeModal from "@/assets/closeModal.svg";
import { makeStyles } from "@material-ui/core/styles";
import arrow from "@/assets/arrow.png";
import { useEffect } from 'react';
import { CustomJackpotModel } from '@/components/Elements/custom-jackpot-modal';
import jackpotDrawn from "../../../assets/Powerball Jackpot is drawn.gif";
interface jackpotlostModalProps {
    open: boolean;
    onClose: () => void;
    message: string;
  };

  const useStyles = makeStyles((theme) => ({
    
    
    
    
    
    
  }));

const JackpotLostModal = ({ open, onClose, message }:jackpotlostModalProps) => {

  const classes = useStyles();
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); // Closes the modal after 5 seconds

      return () => clearTimeout(timer); // Clear timeout if the modal is closed before 5 seconds
    }
  }, [open, onClose]);
  return (
    <CustomJackpotModel
    open={open}
    onClose={onClose}
    childComponent={
      <>
        <Stack direction="row" justifyContent="center" alignItems="center" height="100%" sx={{ p: 0 }}>
          <Box
            sx={{ width: "100%", overflow: "hidden" ,  marginTop:{xs:"0px", sm:"-10px", md:"-20px", lg:"-40px"} }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          > 
            <img
              src={jackpotDrawn}
              alt="Jackpot Drawn"
              style={{ width: "100%", maxWidth: "500px", height: "auto" }}
            />
          </Box>
          <Box></Box>
        </Stack>
      </>
    }
  />
  );
};

export default JackpotLostModal;
