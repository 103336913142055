import { Close } from "@mui/icons-material";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modalAfterLogin";
import winnerLogo from "@/assets/winnerLogo.svg";

import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import jackpotWinner from "../../../assets/Powerball Jackpot winner.gif";
import { CustomJackpotModel } from "@/components/Elements/custom-jackpot-modal";
interface jackpotwinModalProps {
  open: boolean;
  onClose: () => void;

  JackpotPrize: any;
  jackpotRound: any;
}

const useStyles = makeStyles((theme) => ({
  ticketContainer: {
    width: "134px",
    height: "60px",
    // background: "#FFFF",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "45px",
    },
    position: "relative",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    padding: "12.86px",
    [theme.breakpoints.down("sm")]: {
      padding: "7.86px",
    },
  },
  detailLabel: {
    height: "11px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "11px",
    color: "rgba(27, 28, 45, 0.6)",
    margin: "0",
  },
  detailNumber: {
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "0",
  },

  arrowImage: {
    position: "absolute",
    width: "30px",
    height: "30px",
    left: "74%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      left: "68%",
    },
  },
  polyup: {
    top: "-15px",
    left: "86%",
    [theme.breakpoints.down("sm")]: {
      height: "30px ! important",
      width: "30px !important",
    },
  },
  polydown: {
    bottom: "-15px",
    transform: "rotate(180deg)",
  },
  winnerModalImg: {
    [theme.breakpoints.down("sm")]: {
      height: "60px ! important",
      width: "60px !important",
    },
  },
}));

const JackpotWinModal = ({
  open,
  onClose,
  jackpotRound,
  JackpotPrize,
}: jackpotwinModalProps) => {
  const classes = useStyles();
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);
  return (
    <CustomJackpotModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" alignItems="center" justifyContent="center" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{ width: "100%", overflow: "hidden" ,  marginTop:{xs:"0px", sm:"-10px", md:"-20px", lg:"-40px"} }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* <img
                src={winnerLogo}
                alt="mailIcon"
                style={{ textAlign: "center" }}
                className={classes.winnerModalImg}
                height="100px"
                width="100px"
              /> */}

              {/* <Paper
                elevation={3}
                className={classes.ticketContainer}
                sx={{
                  marginTop: "0px",
                  marginBottom: "20px",
                  background: "#fff !important",
                  color: "#ffff",
                  display:"flex",
                  alignItem:"center",
                  justifyContent:"center",
                  width:"100% !important",
                  maxWidth:"220px !important",
                  height:"auto !important",
                }}
              > */}

              <img
                src={jackpotWinner}
                alt="You Win the Jackpot"
                style={{ width: "auto", maxWidth: "500px" }}
              />

              {/* </Paper> */}
              <Typography
                component="h6"
                variant="h6"
                sx={{ textAlign: "center" }}
              >
                Winning Amount
              </Typography>
              <Typography
                component="h5"
                variant="h4"
                sx={{
                  mb: { xs: "5px", md: "20px" },
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                $ {JackpotPrize}
              </Typography>
              <Typography
                component="a"
                href="/app/upComingLotteries" 
                sx={{ 
                  fontWeight: "bold",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#0500cb",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Move to Next Lottery
              </Typography>
            </Box>
            <Box></Box>
          </Stack>
        </>
      }
    />
  );
};

export default JackpotWinModal;
