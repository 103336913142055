import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

// Function to fetch the lottery list, returning the full response object
export const getLotteryList = async (page: number, limit: number) => {
  try {
    const response = await axios.get(`/api/v1/user/myLottery`, {
      params: { page, limit },
      headers: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
    return response; // Return the full response object, not just data
  } catch (error) {
    console.error("Failed to fetch lottery list:", error);
    throw error;
  }
};

// Type definitions for the custom hook options
type UseLotteryListOptions = {
  page: number;
  limit: number;
  config?: QueryConfig<QueryFnType>;
};
type QueryFnType = typeof getLotteryList;

// Custom hook for fetching the lottery list
export const useLotteryList = ({
  page,
  limit,
  config,
}: UseLotteryListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['lotteryList', page, limit], // Use page and limit in the query key for caching
    queryFn: () => getLotteryList(page, limit), // Fetch and return full response
    keepPreviousData: true, // Maintain previous data during pagination
    ...config, // Allow passing custom configuration
  });
};
