import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import RegisterForm  from '../components/RegisterForm';
import { useState } from 'react';
import VerifyOTP from '@/components/Modals/verifyOTP';
import { getTicketStatus } from '@/features/Tickets/api/getTicketStatus';
import { ticketListData } from '@/features/Tickets/types';
import storage from '@/utils/storage';

export const Register = () => {
  const navigate = useNavigate();
  const [isOpen,setIsopen]= useState(false);
  const [email, setEmail]= useState('')
  const handleClose = () => {
    setIsopen(false);
  };

  let valueData: Promise<ticketListData[]>;
  
  const handleLogin = async  () => {
    navigate('/auth/login')

 }

 

  return (
    <Layout title="Register your account">
      <RegisterForm onSuccess={(email) => {setIsopen(true); setEmail(email)}} />
      <VerifyOTP open={isOpen} onClose={handleClose} email={email} onSuccess={handleLogin}/>
    </Layout>
  );
};
