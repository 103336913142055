
import { Box, Stack } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import { CustomJackpotModel } from '@/components/Elements/custom-jackpot-modal';
import jackpotMoveToNext from "../../../assets/Powerball Jackpot move to  next lottery.gif";
interface jackpotlostModalProps {
    open: boolean;
    onClose: () => void;
    message: string;
  };

  const useStyles = makeStyles((theme) => ({
  
  }));

const JackpotMoveToNextLotteryModal = ({ open, onClose, message }:jackpotlostModalProps) => {

  const classes = useStyles();
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); 

      return () => clearTimeout(timer); 
    }
  }, [open, onClose]);
  return (
    <CustomJackpotModel
    open={open}
    onClose={onClose}
    childComponent={
      <>
        <Stack direction="row" justifyContent="center" alignItems="center" height="100%" sx={{ p: 0 }}>
          <Box
            sx={{ width: "100%", overflow: "hidden", marginTop:{xs:"0px", sm:"-10px", md:"-20px", lg:"-40px"} }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
          


            <img
              src={jackpotMoveToNext}
              alt="Jackpot Move to next lottery"

              style={{ width: "auto", maxWidth: "500px",  }}
            />

           
           
           
          </Box>
          <Box></Box>
        </Stack>
      </>
    }
  />
  );
};

export default JackpotMoveToNextLotteryModal;
 