import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Link,
  Pagination,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import HomeScreenImg from "@/assets/earth.jpg";
import comingsoon from "@/assets/comingSoon.png";
import upcomingImg from "@/assets/logo.png";
import { theme } from "@/components/Elements/theme";
import { useAuth } from "@/lib/auth";
import { useLotteries } from "../api/getLottery";
import { CircularProgress } from "@mui/material";
import { lotteryData } from "@/features/buyNow/types";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.png";
import "../../../../src/components/Elements/CSS/style.css";
import storage from "@/utils/storage";
import axios from "axios";
import moment from "moment";

interface Lottery {
  _id: string;
  lotteryName: string;
  ticketPrice: number;
  numOfTicketOnSale: number;
  numOfTicketSold: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  lotteryDrawDate: string;
  totalDraw: number;
  drawPrize: string[];
  status: boolean;
  image: string;
  createdBy: string;
  soldStatus: boolean;
  endDateTime: string;
}

interface APIResponse {
  message: string;
  data: Lottery[];
  page: number;
  limit: number;
  totalPages: number;
  totalRecords: number;
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
}));

const dataValue = {
  heading: "Buy Lottery Tickets Online",
  subHeading:
    "Buy lottery tickets online for the top international lotteries. Safely and securely playfor the biggest jackpots in the entire world from the comfort of home.",
};

export default function UpcomingLotteries() {
  const classes = useStyles();
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);

  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 10;

  const [lotteries, setLotteries] = useState<Lottery[]>([]);
  console.log(lotteries, "lotteries");
  const [error, setError] = useState<string | any>(null);
  const getUpcomingLotteries = async (page: number) => {
    const token = storage.getToken();

    try {
      setLoading(true);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(timezone, "timezone");
      const response = await axios.get(
        `${process.env.API_URL_PREFIX}/api/v1/admin/upcommingLotteries?page=${page}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            timeZone: timezone,
          },
        }
      );
      setLotteries(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.page);
    } catch (err) {
      console.error("Error fetching upcoming lotteries:", err);
      setError("Failed to fetch upcoming lotteries. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUpcomingLotteries(currentPage);
  }, [currentPage]);

  // Handler for next page click
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value); // Update the page when the user clicks on the pagination controls
  };
  const handleSoldOut = (soldStatus, cardId) => {
    if (!soldStatus) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(`/app/Lotteries/${cardId}`);
      }, 500);
    } else {
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: "Tickets are sold-out, please buy tickets in another lottery",
      });
    }
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#3C37FF",
    borderRadius: "10px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var button = {
    background: "rgba(255, 255, 255, 0.13)",
    borderRadius: "15.507px",
    color: "#FFFFFF",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.13)",
      borderRadius: "15.507px",
      color: "#FFFFFF",
    },
  };

  if (lotteries.length === 0) {
    return (
      <Box
        className="w-full h-48 flex justify-center items-center"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          border: "1px solid",
          marginTop: "90px",
          minHeight: "calc(100vh - 330px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media screen and (max-width:992px)": {
            marginTop: "66px",
          },
        }}
      >
        {/* <img src={comingsoon} alt="comingsoon" style={{ width: "100%" }} /> */}
        <img  src="/Comp_1.gif" alt="comingsoon" style={{ alignItems: "center" }} />
      </Box>
    );
  }

  const targetDates = lotteries?.map((value) =>
    moment(
      `${value?.endDate.split(" ")[0]} ${value?.endTime}`,
      "YYYY-MM-DD hh:mm A"
    ).isValid()
      ? moment(
        `${value?.endDate.split(" ")[0]} ${value?.endTime}`,
        "YYYY-MM-DD hh:mm A"
      ).toDate()
      : moment(
        `${value?.endDate.split(" ")[0]} 00:00`,
        "YYYY-MM-DD HH:mm"
      ).toDate()
  );

  console.log(targetDates, "targetDates");

  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        padding: "170px 50px 30px",
      }}
    >
      <Box
        sx={{
          maxWidth: "95%",
          margin: "0 auto",
          [theme.breakpoints.down("md")]: {},
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "768px",
            margin: {
              xs: "10px auto 30px",
              sm: "30px auto 40px",
              md: "60px auto",
            },
            textAlign: "center",
            fontFamily: '"Ubuntu", sans-serif',
            fontSize: "25px",
            lineHeight: "1.5",
          }}
        >
          <Typography
            component="h4"
            sx={{
              textTransform: "capitalize",
              fontWeight: "500 !important",
              marginBottom: { xs: "18px", md: "25px" },
              fontSize: { xs: "20px", md: "30px" },
            }}
          >
            Upcoming Lottery
          </Typography>
          {/* <Typography
            component={"p"}
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Buy lottery tickets online for the top international lotteries.
            Safely and securely playfor the biggest jackpots in the entire world
            from the comfort of home.
          </Typography> */}
        </Box>
        {!loading ? (
          <Box
            sx={{
              padding: { md: "40px 40px 60px", xs: "20px 20px 40px" },
              backgroundColor: "rgb(57 57 57 / 80%)",
              borderRadius: "10px",
            }}
          >
            <Grid container display={"flex"} direction={"column"}>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Grid
                  className="small-device-upComingLotteries"
                  container
                  display={"flex"}
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    marginBottom: "40px",
                    // p: { md: theme.spacing(1), xs: "10px" },
                    pt: { md: "30px", xs: "15px" },
                  }}
                >
                  <Backdrop
                    open={loading}
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                  ></Backdrop>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {lotteries?.map((value, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                // className="inner-grid"
                >
                  <Card
                    style={content}
                    onClick={() => handleSoldOut(value?.soldStatus, value?._id)}
                    sx={{
                      position: "relative",
                      margin: "0 auto",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#fff !important",
                        color: "black !important",
                        boxShadow: 20,
                        minHeight: "182px",
                        "& div div .MuiDivider-root": {
                          borderBottomColor: "black !important",
                        },
                        "& div h5": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div .MuiCardContent-root": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div div div.MuiPaper-root": {
                          border: "1.23px solid #00000038 !important",
                          background: "#fff",
                          boxShadow: "none",
                        },
                        "& div div button": {
                          background: "rgba(255, 255, 255, 0.13)",
                          borderRadius: "15.507px",
                          color: "#FFFFFF",
                        },
                        "&  .MuiCardActions-root  > button": {
                          background: "rgba(255, 255, 255, 0.13)",
                          borderRadius: "15.507px",
                          color: "#FFFFFF",
                        },
                        "& .BuyTicket": {
                          background: "#3c37ff69",
                          color: "#3C37FF !important",
                        },
                      },
                    }}
                  >
                    <Chip
                      label={`${value?.numOfTicketSold || 0}  Sold`}
                      sx={{
                        position: 'absolute',
                        transform: "rotate(45deg)",
                        top: '7px',
                        right: '-42px',
                        backgroundColor: "blue",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                        padding: "8px 26px",
                        fontSize: '12px',
                        fontWeight: 'bold',
                        zIndex: 1,
                      }}
                    />
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        className="img-card"
                        sx={{
                          minHeight: "250px",
                          backgroundPosition: "center",
                          backgroundColor: "#000",
                        }}
                        image={
                          value.image
                            ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                            : upcomingImg
                        }
                        title="green iguana"
                      />

                      {value?.soldStatus ? (
                        <>
                          <Chip
                            label="Sold Out"
                            sx={{
                              position: "absolute",
                              transform: "rotate(-45deg)",
                              top: "7px",
                              left: "-39px",
                              backgroundColor: "red",
                              color: "#FFFFFF",
                              borderRadius: "4px",
                              padding: "8px 26px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              zIndex: 1,
                            }}
                          />
                          {/* <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                borderBottom: '20px solid transparent', // Customize the size of the slanting line
                borderLeft: '20px solid #FF0000', // Match the background color of the label
                zIndex: 0,
              }}
            /> */}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <CardContent
                      style={center}
                      onClick={() =>
                        handleSoldOut(value?.soldStatus, value?._id)
                      }
                      sx={{
                        flexDirection: "column",
                        pb: "0px",
                        cursor: "pointer",
                      }}
                    >
                      {/* <Typography component="h5" variant="h5" gutterBottom>
                  {value.lotteryName} 
                
                  </Typography> */}
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{ display: "flex" }}
                      >
                        <img
                          style={{
                            height: "auto",
                            width: "15px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          src={yellowt}
                        />{" "}
                        {value.ticketPrice}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: "16px", display: "flex" }}
                      >
                        {/* <Countdown
                          date={(() => {
                            const targetDateTime = moment(
                              `${value?.endDate.split(" ")[0]} ${
                                value?.endTime
                              }`,
                              "YYYY-MM-DD hh:mm A"
                            ).format("YYYY-MM-DD HH:mm:ss");

                            const targetMoment = moment(
                              targetDateTime,
                              "YYYY-MM-DD HH:mm:ss"
                            );

                            console.log(
                              "Formatted Target Date:",
                              targetDateTime
                            );
                            console.log(
                              "Current Time:",
                              moment().format("YYYY-MM-DD HH:mm:ss")
                            );

                            if (targetMoment.isBefore(moment())) {
                              targetMoment.add(1, "day").startOf("day");
                              console.log(
                                "Adjusted Target Time:",
                                targetMoment.format("YYYY-MM-DD HH:mm:ss")
                              );
                            }

                            return targetMoment.toDate();
                          })()}
                          // zeroPadTime={true}  // Optional: ensure zero padding for countdown
                        /> */}

                        {value?.endDateTime && moment(value.endDateTime).isAfter(moment()) && (
                          <Countdown
                            date={moment(value.endDateTime).toDate()} // Directly pass the valid Date object
                          />
                        )}


                      </Typography>
                      <Box
                        className="BuyTicket"
                        sx={{
                          px: "14px",
                          py: "5.73px",
                          marginBottom: "5px !important",
                          background: "rgba(255, 255, 255, 0.5)",
                          borderRadius: "15.507px",
                          color: "#fff !important",
                          width: "115px !important",
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        Buy Ticket
                      </Box>

                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          textAlign: "center",
                          mb: "5px",
                        }}
                      >
                        {/* Display formatted endDate */}
                        {moment(
                          `${value?.endDate} ${value?.endTime}`,
                          "YYYY-MM-DD HH:mm:ss A"
                        ).format("DD-MM-YYYY")}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          textAlign: "center",
                          mb: "5px",
                        }}
                      >
                        {/* Display formatted endTime */}
                        {value?.endTime}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" marginTop="40px">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "white",
                      fontSize: "18px",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "primary",
                      color: "white",
                      fontSize: "25px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // or use a fixed height if necessary
              padding: { md: "40px 40px 60px", xs: "20px 20px 40px" },
              backgroundColor: "rgb(57 57 57 / 80%)",
              borderRadius: "10px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}
