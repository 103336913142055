import { Box, CircularProgress, Grid, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopContainer from "./topContainer";
import UpcomingLottery from "./upcomingLottery";
import QuickDraws from "./quickDraws";
import WinningDetails from "./winningDetails";
import { theme } from "@/components/Elements/theme";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { getHomeData, useHomeScreenList } from "../api/getHomeData";
import MyButtonBox from "@/components/Elements/crouselButton";
import RecentActivitiesHome from "./RecentActivitiesHOme";
import storage from "@/utils/storage";
interface Lottery {
  _id: string;
  lotteryName: string;
  ticketPrice: number;
  numOfTicketOnSale: number;
  numOfTicketSold: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  lotteryDrawDate: string;
  totalDraw: number;
  drawPrize: string[];
  status: boolean;
  image: string;
  createdBy: string;
  soldStatus: boolean;
}

interface APIResponse {
  message: string;
  data: Lottery[];
  page: number;
  limit: number;
  totalPages: number;
  totalRecords: number;
}
export const HomeScreen = () => {
  const hidden = useMediaQuery("(min-width: 567px)");
  const hiddenDesktop = useMediaQuery("(max-width: 568px)");
  const [status, setStatus] = useState(false)
  const [lotteries, setLotteries] = useState<Lottery[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | any>(null);
  const getData = async () => {
    const res = await axios.get(`${process.env.API_URL_PREFIX}/api/v1/user/getsys`);
    setStatus(res?.data?.data[0]?.status || false)
  };
  console.log(status , "abhay psklilakl")
  const lotteryData = useHomeScreenList({})
  console.log(lotteryData?.data, "lotteryData")
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);


  const getUpcomingLotteries = async (page: number) => {
    const token = storage.getToken();

    try {
      setLoading(true);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axios.get(
        `${process.env.API_URL_PREFIX}/api/v1/admin/upcommingLotteries?page=${page}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            timeZone: timezone,
          },
        }
      );
      setLotteries(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.page); 
    } catch (err) {
      console.error("Error fetching upcoming lotteries:", err);
      setError("Failed to fetch upcoming lotteries. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUpcomingLotteries(currentPage);
  }, [currentPage]); 

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value); // Update the page when the user clicks on the pagination controls
  };
  return (
    // <>
    //   {hidden ? (
    //     <Box sx={{ color: "#FFFFFF", pl: {xs:"20px",sm:"30px", lg:"70px"}, pr:{xs:"20px",sm:"30px", lg:"70px"}, minHeight: "100vh" }}>
    //       <TopContainer status={status} dataRecent={lotteryData.data} />

    //       {
    //         loading ? (  <Box
    //           display="flex"
    //           justifyContent="center"
    //           alignItems="center"
    //           sx={{ minHeight: "300px" }}
    //         >
    //           <CircularProgress color="primary" />
    //         </Box>) : (
    //           <>
    //            <UpcomingLottery data={lotteries}/>
    //             <Grid item xs={12}>
    //                 <Box display="flex" justifyContent="center" marginTop="40px">
    //                   <Pagination
    //                      count={totalPages} 
    //                      page={currentPage}
    //                     onChange={handlePageChange}
    //                     color="primary"
    //                     sx={{
    //                       "& .MuiPaginationItem-root": {
    //                         color: "white",
    //                         fontSize: "18px",
    //                       },
    //                       "& .MuiPaginationItem-page.Mui-selected": {
    //                         backgroundColor: "primary",
    //                         color: "white",
    //                         fontSize: "25px",
    //                       },
    //                     }}
    //                   />
    //                 </Box>
    //               </Grid>
    //           </>
               
    //         )
    //       }
        
    //       {/* <QuickDraws  data={lotteryData.data}/> */}
    //       <Grid container spacing={2} marginTop={5}>
    //         <Grid item xs={12} sm={6}><MyButtonBox /></Grid>
    //         <Grid item xs={12} sm={6}><RecentActivitiesHome dataRecent={lotteryData.data} /></Grid>
    //       </Grid>
    //       <WinningDetails  data={lotteryData.data} />
    //     </Box>
    //   ) : (
    //     <Box sx={{ color: "#FFFFFF", pl: {xs:"15px",sm:"30px", lg:"70px"}, pr:{xs:"15px",sm:"30px", lg:"70px"}, minHeight: "100vh" }}>
    //        <TopContainer status={status} dataRecent={lotteryData.data} />
    //        <UpcomingLottery data={lotteryData.data}/>
    //        <WinningDetails  data={lotteryData.data} />
    //        <Grid container spacing={2}>
    //         <Grid item xs={12} sm={6} ><MyButtonBox /></Grid>
    //         <Grid item xs={12} sm={6} ><RecentActivitiesHome dataRecent={lotteryData.data} /></Grid>
    //       </Grid>
    //     </Box>
    //   )}
    // </>



    <>
      <Box sx={{ color: "#FFFFFF", pl: {xs:"20px",sm:"30px", lg:"70px"}, pr:{xs:"20px",sm:"30px", lg:"70px"}, minHeight: "100vh" }}>
          <TopContainer status={status} dataRecent={lotteryData.data} />

          {
            loading ? (  <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "300px" }}
            >
              <CircularProgress color="primary" />
            </Box>) : (
              <>
               <UpcomingLottery data={lotteries}/>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" marginTop="40px">
                      <Pagination
                         count={totalPages} 
                         page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "white",
                            fontSize: "18px",
                          },
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: "primary",
                            color: "white",
                            fontSize: "25px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
              </>
               
            )
          }
        
          {/* <QuickDraws  data={lotteryData.data}/> */}
          <Grid container spacing={2} marginTop={5}>
            <Grid item xs={12} sm={6}><MyButtonBox /></Grid>
            <Grid item xs={12} sm={6}><RecentActivitiesHome dataRecent={lotteryData.data} /></Grid>
          </Grid>
          <WinningDetails  data={lotteryData.data} />
        </Box>
    </>
  );
};

export default HomeScreen;
