import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import yellowt from "@/assets/yellow-t.png";
import upcomingImg from "@/assets/logo.png";
import cool from "@/assets/cool.svg";
import Countdown from "react-countdown";
import moment from "moment";

export const UpcomingLottery = ({ data }) => {
  console.log(data, "data");
  var content = {
    color: "#ffff",
    backgroundColor: "#3C37FF",
    borderRadius: "10px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var button = {
    background: "rgba(255, 255, 255, 0.13)",
    borderRadius: "15.507px",
    color: "#FFFFFF",
  };
  return (
    <>
      {data?.length > 0 ? (
        <>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              marginBottom: { xs: "20px", md: "30px" },
              marginTop: { xs: "30px", md: "40px" },
            }}
          >
            Upcoming Lottery
          </Typography>
          <Grid
            container
            display={"flex"}
            direction={"column"}
            sx={{
              backgroundColor: "rgba(5, 5, 24, 0.8)",
              borderRadius: "10px",
              mt: { xs: "0px", md: "10px" },
            }}
          >
            <Grid item xs={8} sm={8} md={8} lg={6} sx={{ p: { xs: "20px" } }}>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={{ marginBottom: "0px", my: "20px" }}
              >
                {data?.map((value, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    className="inner-grid"
                  >
                    <Card
                      style={content}
                      sx={{
                        position: "relative",
                        margin: "0 auto",
                        "&:hover": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                          boxShadow: 20,
                          cursor: "pointer",
                          minHeight: "182px",
                          backgroundPosition: "50% 0%",
                          backgroundRepeat: "no-repeat",
                          display: "block",
                          /* width: 100%; */
                          backgroundSize: "cover",
                          "& div div .MuiDivider-root": {
                            borderBottomColor: "black !important", // Change the color of the Divider component
                          },
                          "& div h5": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                          },
                          "& div div .MuiCardContent-root": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                          },
                          "& div div div div.MuiPaper-root": {
                            border: "1.23px solid #00000038 !important",
                            background: "#fff",
                            boxShadow: "none",
                          },
                          "& div div button": {
                            background: "rgba(255, 255, 255, 0.13)",
                            borderRadius: "15.507px",
                            color: "#FFFFFF",
                          },
                          "&  .MuiCardActions-root  > button": {
                            background: "rgba(255, 255, 255, 0.13)",
                            borderRadius: "15.507px",
                            color: "#FFFFFF",
                          },
                        },
                      }}
                      onClick={() => window.location.assign(`/auth/login`)}
                    >
                        <Chip
                                              label={`${value?.numOfTicketSold || 0}  Sold`}
                                              sx={{
                                                position: "absolute",
                                                transform: "rotate(45deg)",
                                                top: "2px",
                                                right: "-31px",
                                                backgroundColor: "#fff",
                                                color: "Red",
                                                borderRadius: "4px",
                                                padding: "4px 17px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                zIndex: 1,
                                              }}
                                            />
                   
                      <CardMedia
                        className="img-card"
                        sx={{
                          height: 156,
                          backgroundPosition: "initial",
                        }}
                        image={
                          value.image
                            ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                            : upcomingImg
                        }
                        title="green iguana"
                      />
                      <CardContent
                        style={center}
                        onClick={() => {
                          window.location.assign(`/auth/login`);
                        }}
                        sx={{
                          flexDirection: "column",
                          pb: "0px",
                          cursor: "pointer",
                          height: "80px",
                          mt: "20px",
                        }}
                      >
                        {/* <Typography component="h5" variant="h5" gutterBottom>
            {value.lotteryName}
           
            </Typography> */}
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{ display: "flex" }}
                        >
                          <img
                            style={{
                              height: "auto",
                              width: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                            src={yellowt}
                          />{" "}
                          {value.ticketPrice}
                        </Typography>
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{ fontSize: "16px", display: "flex" }}
                        >
                          <Countdown
                            date={moment(
                              `${value?.endDate.split(" ")[0]} ${
                                value?.endTime
                              }`,
                              "YYYY-MM-DD hh:mm A"
                            ).toDate()}
                          />
                        </Typography>

                        <Box
                          onClick={() => window.location.assign(`/auth/login`)}
                          sx={{
                            px: "3px",
                            py: "3.73px",
                            marginBottom: "19px !important",
                            background: "rgba(255, 255, 255, 0.5)",
                            borderRadius: "15.507px",
                            color: "#3C37FF !important",
                            width: "115px !important",
                            textAlign: "center",
                            fontWeight: "600",
                          }}
                        >
                          Buy Ticket
                        </Box>
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontSize: "12px !important",
                            display: "flex",
                            textAlign: "center",
                            mb: "5px",
                          }}
                        >
                          {moment(
                            `${value?.endDate} ${value?.endTime}`,
                            "YYYY-MM-DD HH:mm:ss A"
                          ).format("DD-MM-YYYY")}
                          {"            "}
                          {value?.endTime}
                        </Typography>
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontSize: "12px !important",
                            display: "flex",
                            textAlign: "center",
                            mb: "5px",
                          }}
                        >
                          {/* Display formatted endTime */}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default UpcomingLottery;
