import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { LiveLotteries } from '../types';

export const getLottery = async (): Promise<LiveLotteries[]> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(timezone ,"timezone")
  const response = await axios.get(`/api/v1/user/dashboarddata`, {
    headers: {
      'TimeZone': timezone, 
    },
  });
  const data = response
  return data.data;
};
//Response.data.upc
type QueryFnType = typeof getLottery;

type UseLotteriesOptions = {
  config?: any;
};

export const useLotteries = ({  config }: UseLotteriesOptions) => {
  return useQuery<LiveLotteries[]>({
    queryKey: ['LiveLotteries'],
    queryFn: () => getLottery(),
    ...config,
  });
};