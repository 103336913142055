import { Box, Fade, Modal } from "@mui/material";
import React from "react";
import { theme } from "./theme";

const style = {
    position: "absolute",
    top: "0%",
    left: "0%",
    // transform: { xs:  "translate(-50%, -50%)", sm:  "translate(-50%, -50%)", md:  "translate(-50%, -50%)", },
    // maxWidth: { xs: '90%', sm: '383px', md: '529px', lg: '529px' },

    width: '100%',
    height: '100%',
    backgroundColor: "rgba(87, 87, 87, 0.64)",
    p: 0, 
    borderRadius: "8px",
    overflow: 'auto',
    border: '1px solid black'
};

interface iModalProps {
    open: boolean;
    onClose: () => void;
    childComponent: any;
};

export const CustomJackpotModel = ({
    open,
    onClose,
    childComponent
}: iModalProps) => {
    return (
        <Modal closeAfterTransition open={open}>
            <Fade in={open}>
                <Box sx={style}>
                    {childComponent}
                </Box>
            </Fade>
        </Modal>
    )
}
