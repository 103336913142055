import  { initReactQueryAuth }  from 'react-query-auth';
import { useMutation } from 'react-query';
import {
  loginWithEmailAndPassword,
  //getUser,
  registerWithEmailAndPassword,
  resetPassword,
  verifyOTPWithOTP,
  forgetPasswordWithEmail,
  UserResponse,
  LoginCredentialsDTO,
  forgetPasswordCredentialsDTO,
  verifyOTPCredentialsDTO,
  resetPasswordCredentialsDTO,
  RegisterCredentialsDTO,
  AuthUser,
  getUser,
} from '@/features/auth';
import storage from '../utils/storage';
import { CircularProgress } from '@mui/material';
import '../App.css';



async function handleUserResponse(data: UserResponse) {
  const { token } = data;
  let userData = {
    "firstName":data.firstName,
    "lastName":data.firstName,
    "email":data.email,
    "nickname":data.nickName,
    "id":data.userId
  }
  //loadUser(userData)
  storage.setToken(token);
  storage.setUserId(data.userId)
  return userData;
}



async function loadUser() {
  if (storage.getToken()) {
    const data = await getUser();
    return data;
  }
  return null;
}
// async function loginFn(data: LoginCredentialsDTO) {
//   const response = await loginWithEmailAndPassword(data);
//   const user = await handleUserResponse(response);
//   console.log(response);
//   return user;
// }


async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  console.log(response);
  return user;
}

async function verifyFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  // const user = await handleUserResponse(response);
  // console.log(response);
  return response;
}

// async function loginFn(data: LoginCredentialsDTO) {
//   const response = await loginWithEmailAndPassword(data);
//   let userData = {
//     "firstName":response.firstName,
//     "lastName":response.firstName,
//     "email":response.email,
//     "nickname":response.nickName,
//     "id":response.userId
//   }
//   // const user = await handleUserResponse(response);
//   console.log(response);
//   return userData;
// }



async function registerFn(data: RegisterCredentialsDTO): Promise<AuthUser>  {
  const response = await registerWithEmailAndPassword(data);
  // const message = await handleUserResponse(response);
  // return message;
  const user: AuthUser = {
    
    id: response.userId,
    email: response.email,
    firstName: response.firstName,
    lastName: response.lastName,
  };

  return user;
}


async function verifyOTPFn(data: verifyOTPCredentialsDTO) {
  const response = await verifyOTPWithOTP (data);
  // const user = await handleUserResponse(response);
  // return user;
  return response;
}


async function logoutFn() {
  storage.clearToken();
  storage.clearUserId();
  storage.cleartUserPassword();
  storage.clearRemeberMe();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  verifyFn,
  verifyOTPFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className='loaderMain' style = {{display:"flex" , justifyContent:"center", alignItems:"center", height:"100vh"}}>
      <CircularProgress size={80}/>
      </div>
    );
  },
};





export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig);


