// // import React, { useEffect, useState } from "react";
// // import cloneDeep from "lodash/cloneDeep";
// // import throttle from "lodash/throttle";
// // import Pagination from "rc-pagination";
// // import "rc-pagination/assets/index.css";
// // import { allData } from "./constants";
// // import storage from "@/utils/storage";
// // import axios from "axios";
// // import "./myWinning.css";
// // import Swal from "sweetalert2";
// // import { Box, Button, CircularProgress, Typography } from "@mui/material";
// // import { Opacity } from "@material-ui/icons";
// // import MervIcon from "@/assets/mervIcon.svg";
// // import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// // import { useInvestedCoins } from "../api/getInvestedCoins";
// // import yellowt from "@/assets/yellow-t.png";
// // import CurrencyFormat from "react-currency-format";
// // const MyWinningList = () => {
// //   const countPerPage = 10;
// //   const dataWallet = useWalletAmount({});
// //   const coins = useInvestedCoins({});
// //   const [value, setValue] = React.useState("");
// //   const [currentPage, setCurrentPage] = React.useState(1);
// //   const [totalRecords, setTotalRecords] = React.useState(10);
// //   const [loading, setLoading] = useState(false);
// //   const [data, setData] = useState([
// //     {
// //       LotteryName: "",
// //       ticketNumber: "",
// //       winAmount: 0,
// //       claimStatus: "",
// //       lotteryStatus: "",
// //       amount: "",
// //       surpriceAmt: 0,
// //       createdDate: "",
// //       createdTime: "",
// //       claimRemark: "",
// //       transactionId: "",
// //     },
// //   ]);
// //   const [collection, setCollection] = React.useState(
// //     cloneDeep(allData.slice(0, countPerPage))
// //   );
// //   const [isLoading, setIsLoading] = useState(false);

// //   const searchData = React.useRef(
// //     throttle((val) => {
// //       const query = val.toLowerCase();
// //       setCurrentPage(1);
// //       const data = cloneDeep(
// //         allData
// //           .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
// //           .slice(0, countPerPage)
// //       );
// //       setCollection(data);
// //     }, 400)
// //   );

// //   const [showLoader, setShowLoader] = useState(false);
// //   React.useEffect(() => {
// //     if (!value) {
// //       updatePage(1);
// //     } else {
// //       searchData.current(value);
// //     }
// //   }, [value]);

// //   useEffect(() => {
// //     getData(value, 1);
// //   }, [value]);

// //   const redeemPrize = async (id: any, amount: any) => {
// //     try {
// //       let token = storage.getToken();
// //       let payload = {
// //         amount: amount,
// //       };
// //       setShowLoader(true);

// //       const res = await axios.post(
// //         `${process.env.API_URL_PREFIX}/api/v1/user/redeemSupriceInWallet/${id}`,
// //         payload,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //             "Access-Control-Allow-Origin": "*",
// //           },
// //         }
// //       );
// //       Swal.fire({
// //         background: "black",
// //         color: "#ffff",
// //         icon: "success",
// //         title: "Success!",
// //         text: "The prize of a free ticket is added to your wallet",
// //       }).then((res) => {
// //         setShowLoader(false);
// //         window.location.reload();
// //       });
// //     } catch (err) {
// //       setShowLoader(false);
// //       Swal.fire({
// //         icon: "error",
// //         background: "black",
// //         color: "#ffff",
// //         title: "Error!",
// //         text: "Oops something went wrong. Please try again later.",
// //       }).then((res) => {
// //         window.location.reload();
// //       });
// //     }
// //   };

// //   const winAmountClaim = async (id: any, winAmount: any) => {
// //     try {
// //       let token = storage.getToken();
// //       let payload = {
// //         transactionId: id,
// //         amount: winAmount,
// //       };
// //       setIsLoading(true);
// //       const res = await axios.post(
// //         `${process.env.API_URL_PREFIX}/api/v1/user/winAmountClaim`,
// //         payload,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //             "Access-Control-Allow-Origin": "*",
// //           },
// //         }
// //       );
// //       Swal.fire({
// //         background: "black",
// //         color: "#ffff",
// //         icon: "success",
// //         title: "Success!",
// //         text: res?.data?.message,
// //       });
// //       setIsLoading(false);
// //     } catch (err: any) {
// //       Swal.fire({
// //         icon: "error",
// //         background: "black",
// //         color: "#ffff",
// //         text: err?.response.data?.message,
// //       });
// //     }
// //   };
// //   const getData = async (value, p) => {
// //     try {
// //       let token = storage.getToken();
// //       let pageLimit = value !== "" ? totalRecords : 10;

// //       const payable = {
// //         page: p,
// //         limit: pageLimit,
// //       };

// //       setLoading(true);

// //       const response = await axios.post(
// //         `${process.env.API_URL_PREFIX}/api/v1/user/myWinning?search=${value}`,
// //         payable,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //             "Access-Control-Allow-Origin": "*",
// //           },
// //         }
// //       );

// //       // If successful, update state with the response data
// //       setData(response.data.data);
// //       setTotalRecords(response.data.winningPageCount);
// //     } catch (error) {
// //       // Handle errors here
// //       console.error("Error fetching data:", error);
// //     } finally {
// //       // Always executed after try/catch
// //       setLoading(false);
// //     }
// //   };

// //   const updatePage = (p) => {
// //     setCurrentPage(p);
// //     const to = countPerPage * p;
// //     const from = to - countPerPage;
// //     setCollection(cloneDeep(allData.slice(from, to)));
// //     getData(value, p);
// //   };

// //   return (
// //     <>
// //       <div style={{ overflow: "auto" }}>
// //         <input
// //           placeholder="Search"
// //           value={value}
// //           onChange={(e) => setValue(e.target.value)}
// //           className="search"
// //         />
// //         <Typography
// //           variant="h5"
// //           sx={{
// //             position: "absolute",
// //             right: "10%",
// //             margin: "-18px",
// //             pt: { xs: "5px", md: "0" },
// //           }}
// //           style={{ margin: "-58px 10px" }}
// //         >
// //           <img
// //             src={MervIcon}
// //             className="log-merv-wallet"
// //             style={{
// //               height: "22px",
// //               width: "22px",
// //               position: "relative",
// //               top: "5px",
// //             }}
// //           />{" "}
// //           {coins?.data?.data} <br />
// //           <img
// //             src={yellowt}
// //             className="log-merv-wallet"
// //             style={{
// //               height: "22px",
// //               width: "22px",
// //               position: "relative",
// //               top: "5px",
// //               right: "5px",
// //             }}
// //           />
// //           <CurrencyFormat
// //             value={dataWallet?.data?.data?.walletAmount}
// //             thousandSeparator={","}
// //             displayType={"text"}
// //             decimalSeparator={"."}
// //           />
// //         </Typography>
// //       </div>

// // <div className="table-t1">
// //         {loading ? (
// //           <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
// //             <CircularProgress /> {/* Loader while fetching data */}
// //           </Box>
// //         ) : data.length > 0 ? (
// //           <table style={{ borderCollapse: "collapse", width: "100%" }}>
// //           <tr className="table-th">
// //             <th>Lottery Name</th>
// //             <th>Ticket Number</th>
// //             <th>Win Amount</th>
// //             <th>Claim</th>
// //             <th>Redeem</th>
// //             <th>Win Status</th>
// //             <th>Ticket Price</th>
// //             <th>CreatedAt</th>
// //             <th>Claim Remark</th>
// //           </tr>
// //           {data.map((item, i) => (
// //             <tr key={i} className="table-th2">
// //               <td>{item?.LotteryName}</td>
// //               <td>{item?.ticketNumber}</td>
// //               <td>{item?.winAmount}</td>
// //               {Number(item?.winAmount) > 0 &&
// //                 item?.claimStatus !== "Approved" &&
// //                 item?.claimStatus !== "Pending" ? (
// //                 <td>
// //                   {item?.claimStatus === "Rejected" ? (
// //                     <button
// //                       onClick={() =>
// //                         winAmountClaim(item?.transactionId, item?.winAmount)
// //                       }
// //                       className="claim-reject-btn"
// //                     >
// //                       Rejected
// //                     </button>
// //                   ) : (
// //                     <button
// //                       onClick={() =>
// //                         winAmountClaim(item?.transactionId, item?.winAmount)
// //                       }
// //                       className="claim-btn"
// //                     >
// //                       Claim
// //                     </button>
// //                   )}
// //                 </td>
// //               ) : (
// //                 <td>
// //                   {item?.claimStatus !== "Pending" ? (
// //                     item?.claimStatus !== "Approved" ? (
// //                       <button
// //                         onClick={() =>
// //                           winAmountClaim(item?.transactionId, item?.winAmount)
// //                         }
// //                         className="claim-btn-disabled"
// //                         disabled
// //                       >
// //                         Claim
// //                       </button>
// //                     ) : (
// //                       <button
// //                         className="claim-btn-approve-disabled"
// //                         disabled
// //                       >
// //                         Approved
// //                       </button>
// //                     )
// //                   ) : (
// //                     <button className="claim-btn-process-disabled" disabled>
// //                       Processing
// //                     </button>
// //                   )}
// //                 </td>
// //               )}
// //               {Number(item?.surpriceAmt) > 0 ? (
// //                 <td>
// //                   <button
// //                     onClick={() =>
// //                       redeemPrize(item?.transactionId, item?.surpriceAmt)
// //                     }
// //                     className="redeem-btn"
// //                     disabled={showLoader}
// //                   >
// //                     {showLoader ? <div>Redeem</div> : <>Redeem</>}
// //                   </button>
// //                 </td>
// //               ) : (
// //                 <td>
// //                   <button
// //                     onClick={() =>
// //                       redeemPrize(item?.transactionId, item?.surpriceAmt)
// //                     }
// //                     className="redeem-btn-disabled"
// //                     disabled
// //                   >
// //                     Redeem
// //                   </button>
// //                 </td>
// //               )}
// //               <td>{item?.lotteryStatus}</td>
// //               <td>{item?.amount}</td>
// //               <td>{item?.createdDate}</td>
// //               <td>{item?.claimRemark}</td>
// //             </tr>
// //           ))}
// //         </table>
// //         ) : (
// //           <div style={{ textAlign: "center", padding: "20px" }}>
// //             No data found
// //           </div>
// //         )}
// //       </div>

// //       {
// //                loading  ?(
// //                   <Box className="paginationBox">
// //                     ""
// //                   </Box>
// //                 ):(
// //         <Box className="paginationBox">
// //           <Pagination
// //             pageSize={countPerPage}
// //             onChange={updatePage}
// //             current={currentPage}
// //             total={totalRecords}
// //           />
// //         </Box>
// //         )
// //       }

// //     </>

// //   );
// // };
// // export default MyWinningList;

// import React, { useState, useEffect, useMemo } from "react";
// import cloneDeep from "lodash/cloneDeep";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import { allData } from "./constants";
// import storage from "@/utils/storage";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   CircularProgress,
//   Grid,
//   Paper,
//   Stack,
//   styled,
//   Typography,
// } from "@mui/material";
// import MervIcon from "@/assets/mervIcon.svg";
// import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import { useInvestedCoins } from "../api/getInvestedCoins";
// import yellowt from "@/assets/yellow-t.png";
// import CurrencyFormat from "react-currency-format";
// import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
// import { MantineProvider, Table, Container } from "@mantine/core";
// import Swal from "sweetalert2";
// import moment from "moment";
// import "./myWinning.css";
// import { theme } from "@/components/Elements/theme";
// import { Payment } from "./MyLottery";

// const MyWinningList = () => {
//   const countPerPage = 10;
//   const dataWallet = useWalletAmount({});
//   const coins = useInvestedCoins({});
//   const [value, setValue] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalRecords, setTotalRecords] = useState(10);
//   const [loading, setLoading] = useState(false);
//   const [data, setData]: any = useState([
//     {
//       LotteryName: "",
//       amount: "",
//       claimPaymentId: "",
//       claimRemark: "",
//       claimStatus: "",
//       createdDate: "",
//       lotteryDrawDate: "",
//       lotteryStatus: "",
//       startDate: "",
//       surpriceAmt: "",
//       ticketNumber: "",
//       transactionId: "",
//       winAmount: "",
//     },
//   ]);

//   const transformData = (data: any) => {
//     return data.map((item) => {
//       return {
//         LotteryName: item.LotteryName || "N/A",
//         amount: item.amount || "N/A",
//         claimPaymentId: item.claimPaymentId || "N/A",
//         claimRemark: item.claimRemark || "N/A",
//         claimStatus: item.claimStatus || "N/A",
//         createdDate: item.createdDate || "N/A",
//         lotteryDrawDate: item.lotteryDrawDate || "N/A",
//         lotteryStatus: item.lotteryStatus || "N/A",
//         startDate: item.startDate || "N/A",
//         surpriceAmt: item.surpriceAmt || "N/A",
//         ticketNumber: item.ticketNumber || "N/A",
//         transactionId: item.transactionId || "N/A",
//         winAmount: item.winAmount,
//       };
//     });
//   };

//   console.log(transformData(data), "data");
//   const [collection, setCollection] = useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );

//   useEffect(() => {
//     getData(value, 1);
//   }, [value]);

//   const getData = async (value, p) => {
//     try {
//       let token = storage.getToken();
//       let pageLimit = value !== "" ? totalRecords : 10;

//       const payload = {
//         page: p,
//         limit: pageLimit,
//       };

//       setLoading(true);

//       const response = await axios.post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/myWinning?search=${value}`,
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//             TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
//           },
//         }
//       );

//       setData(response.data.data);
//       setTotalRecords(response.data.winningPageCount);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updatePage = (p) => {
//     setCurrentPage(p);
//     getData(value, p);
//   };

//   const columns = useMemo<MRT_ColumnDef[]>(
//     () => [
//       {
//         accessorKey: "LotteryName",
//         header: "Lottery Name",
//       },
//       {
//         accessorKey: "ticketNumber",
//         header: "Ticket Number",
//       },
//       {
//         accessorKey: "winAmount",
//         header: "Win Amount",
//         Cell: ({ cell }) => <span>{cell.getValue() as string}</span>,
//       },
//       // {
//       //   accessorKey: 'claimStatus',
//       //   header: 'Claim Status',
//       // },
//       // {
//       //   accessorKey: 'lotteryStatus',
//       //   header: 'Lottery Status',
//       // },

//       {
//         accessorKey: "lotteryStatus",
//         header: "Status",
//         size: 70,
//         Cell: ({ cell }) => (
//           <Box
//             sx={() => ({
//               color:
//                 cell.getValue<string>() === "Winner"
//                   ? "#95D23D"
//                   : cell.getValue<string>() === "Loose"
//                   ? "#FF4545"
//                   : cell.getValue<string>() === "Pending"
//                   ? "#FF9900"
//                   : "#FF4545",
//               borderRadius: "4px",
//               border:
//                 cell.getValue<string>() === "Winner"
//                   ? "1px solid rgba(149, 210, 61, 0.41)"
//                   : cell.getValue<string>() === "Loose"
//                   ? "1px solid rgba(255, 69, 69, 0.4)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "1px solid rgba(231, 214, 61, 0.5)"
//                   : "1px solid rgba(255, 69, 69, 0.4)",
//               backgroundColor:
//                 cell.getValue<string>() === "Winner"
//                   ? "rgba(149, 210, 61, 0.19)"
//                   : cell.getValue<string>() === "Loose"
//                   ? "rgba(255, 69, 69, 0.05)"
//                   : cell.getValue<string>() === "Pending"
//                   ? "rgba(231, 214, 61, 0.1)"
//                   : "rgba(255, 69, 69, 0.05)",
//               maxWidth: "9ch",
//               padding: "4px",
//             })}
//           >
//             {cell.getValue<string>() === "Pending"
//               ? "Pending"
//               : cell.getValue<string>()}
//           </Box>
//         ),
//       },
//       {
//         accessorKey: "amount",
//         header: "Ticket Price",
//       },
//       {
//         accessorKey: "createdDate",
//         filterVariant: "text",
//         header: "Created At",
//         size: 20,
//         Cell: ({ cell }) => {
//           const formattedDate = cell.getValue() as string; // Change this format to your desired one
//           return <Box>{formattedDate}</Box>;
//         },
//       },
//       // {
//       //   accessorKey: 'claimRemark',
//       //   header: 'Claim Remark',
//       //   filterVariant: "text",
//       // },
//       // {
//       //   id: 'actions',
//       //   header: 'Actions',
//       //   Cell: ({ row }) => (
//       //     <div>
//       //       <Button onClick={() => redeemPrize(row.original.transactionId, row.original.surpriceAmt)}>Redeem</Button>
//       //     </div>
//       //   ),
//       // },
//     ],
//     []
//   );

//   const redeemPrize = async (id, amount) => {
//     try {
//       let token = storage.getToken();
//       const payload = { amount };

//       setLoading(true);
//       await axios.post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/redeemSupriceInWallet/${id}`,
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       );

//       Swal.fire({
//         icon: "success",
//         title: "Success!",
//         text: "The prize has been added to your wallet.",
//       });

//       setLoading(false);
//       window.location.reload();
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Error!",
//         text: "An error occurred, please try again.",
//       });
//       setLoading(false);
//     }
//   };
//   const Item = styled(Paper)(() => ({
//     background: "transparent",
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//   }));

//   var cardStyle = {
//     display: "block",
//     transitionDuration: "0.3s",
//     height: "auto",
//     background: "#050518",
//     boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
//     borderRadius: "20px",
//   };

//   return (
//     <Box sx={{ height: "auto" }}>
//       <Grid container sx={{ mt: { md: theme.spacing(0.5), xs: "10px" } }}>
//         <Grid item xs={12} md={12}>
//           {" "}
//           <Card
//             style={cardStyle}
//             sx={{
//               p: { md: theme.spacing(0.3), xs: "0px" },
//               m: theme.spacing(0.2),
//             }}
//           >
//             <CardContent>
//               <Stack spacing={2}>
//                 <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
//                     {" "}
//                     <Typography
//                       component="h4"
//                       sx={{
//                         fontSize: { xs: "20px", md: "22px" },
//                       }}
//                     >
//                       My Winning
//                     </Typography>
//                     <Typography
//                       variant="h5"
//                       sx={{  
//                         pt: { xs: "5px", md: "0" },
//                       }}
//                     >
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                         <img
//                           src={MervIcon}
//                           className="log-merv-wallet"
//                           style={{
//                             height: "22px",
//                             width: "22px",
//                             position: "relative", 
//                           }}
//                         />{" "}
//                         {coins?.data?.data}  
//                       </Typography>
//                       <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
//                       <img
//                         src={yellowt}
//                         className="log-merv-wallet"
//                         style={{
//                           height: "22px",
//                           width: "22px",
//                           position: "relative", 
//                         }}
//                       />
//                       <CurrencyFormat
//                         value={dataWallet?.data?.data?.walletAmount}
//                         thousandSeparator={","}
//                         displayType={"text"}
//                         decimalSeparator={"."}
//                       />
//                       </Typography>
//                     </Typography>
//                 </Item>
//                 <Grid item sx={{ width: "100%", overflowX: "auto" }}>
//                   <MantineProvider
//                     withGlobalStyles
//                     withNormalizeCSS
//                     theme={{
//                       breakpoints: {
//                         xs: "30em",
//                         sm: "48em",
//                         md: "64em",
//                         lg: "74em",
//                         xl: "90em",
//                       },
//                       fontSizes: { xs: "12px", md: "14px" },
//                       colorScheme: "dark",
//                     }}
//                   >
//                     {loading ? (
//                       <Box
//                         sx={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           minHeight: "200px",
//                         }}
//                       >
//                         <CircularProgress />
//                       </Box>
//                     ) : (
//                       <MantineReactTable
//                         columns={columns}
//                         data={transformData(data) || []}
//                         enablePagination={false}
//                         initialState={{ showColumnFilters: true }}
//                         positionToolbarAlertBanner="bottom"
//                       />
//                     )}
//                   </MantineProvider>
//                 </Grid>
//                 {/* Pagination */}
//                 <Box className="paginationBox" sx={{ marginTop: "20px" }}>
//                   <Pagination
//                     pageSize={countPerPage}
//                     current={currentPage}
//                     total={totalRecords}
//                     onChange={updatePage}
//                   />
//                 </Box>
//               </Stack>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default MyWinningList;



// import React, { useEffect, useState } from "react";
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import { allData } from "./constants";
// import storage from "@/utils/storage";
// import axios from "axios";
// import "./myWinning.css";
// import Swal from "sweetalert2";
// import { Button, Typography } from "@mui/material";
// import { Opacity } from "@material-ui/icons";
// import MervIcon from "@/assets/mervIcon.svg";
// import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import { useInvestedCoins } from "../api/getInvestedCoins";
// import yellowt from "@/assets/yellow-t.png";
// import CurrencyFormat from 'react-currency-format';
// const MyWinningList = () => {
//   const countPerPage = 10;
//   const dataWallet = useWalletAmount({});
//   const coins = useInvestedCoins({});
//   const [value, setValue] = React.useState("");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [totalRecords, setTotalRecords] = React.useState(10);
//   const [data, setData] = useState([
//     {
//       LotteryName: "",
//       ticketNumber: "",
//       winAmount: 0,
//       claimStatus: "",
//       lotteryStatus: "",
//       amount: "",
//       surpriceAmt: 0,
//       createdDate: "",
//       createdTime: "",
//       claimRemark: "",
//       transactionId: "",
//     },
//   ]);
//   const [collection, setCollection] = React.useState(
//     cloneDeep(allData.slice(0, countPerPage))
//   );
//   const [isLoading, setIsLoading] = useState(false);

//   const searchData = React.useRef(
//     throttle((val) => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );
//   const column = Object.keys(data[0]);
// const [showLoader,setShowLoader]=useState(false)
//   React.useEffect(() => {
//     if (!value) {
//       updatePage(1);
//     } else {
//       searchData.current(value);
//     }
//   }, [value]);

//   useEffect(() => {
//     getData(value, 1);
//   }, [value]);
//   console.log("value win", value);
//   console.log("data Winning", data);
//   const redeemPrize = async (id: any, amount: any) => {
//     try {
//       let token = storage.getToken();
//       let payload = {
//         amount: amount,
//       };
//       setShowLoader(true);
//       const res = await axios.post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/redeemSupriceInWallet/${id}`,
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       );
//       Swal.fire({
//         background: "black",
//         color: "#ffff",
//         icon: "success",
//         title: "Success!",
//         text: "The prize of a free ticket is added to your wallet",
//       }).then((res) => {
//         setShowLoader(false);
//         window.location.reload();
//       });
//     } catch (err) {
//            setShowLoader(false);
//       Swal.fire({
//         icon: "error",
//         background: "black",
//         color: "#ffff",
//         title: "Error!",
//         text: "Oops something went wrong. Please try again later.",
//       }).then((res) => {
//         window.location.reload();
//       });
//     }
//   };

//   const winAmountClaim = async (id: any, winAmount: any) => {
//     try {
//       let token = storage.getToken();
//       let payload = {
//         transactionId: id,
//         amount: winAmount,
//       };
//       setIsLoading(true);
//       const res = await axios.post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/winAmountClaim`,
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       );
//       Swal.fire({
//         background: "black",
//         color: "#ffff",
//         icon: "success",
//         title: "Success!",
//         text: res?.data?.message,
//       });
//       setIsLoading(false);
//     } catch (err: any) {
//       Swal.fire({
//         icon: "error",
//         background: "black",
//         color: "#ffff",
//         text: err?.response.data?.message,
//       });
//     }
//   };
//   const getData = async (value, p) => {
//     let token = storage.getToken();
//     let pageLimit;
//     pageLimit = value != "" ? totalRecords : 10;
//     const payable = {
//       page: p,
//       limit: pageLimit,
//     };
//     await axios
//       .post(
//         `${process.env.API_URL_PREFIX}/api/v1/user/myWinning?search=${value}`,
//         payable,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Access-Control-Allow-Origin": "*",
//           },
//         }
//       )
//       .then((res) => {
//         setData(res.data.data);
//         setTotalRecords(res.data.winningPageCount);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const updatePage = (p) => {
//     setCurrentPage(p);
//     const to = countPerPage * p;
//     const from = to - countPerPage;
//     setCollection(cloneDeep(allData.slice(from, to)));
//     getData(value, p);
//   };
//   console.log("?search=", value);
//   console.log("Redeem Loader",showLoader );
//   return (
//     <>
//       <div style={{overflow:"auto"}}>
//         <input
//           placeholder="Search"
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
//           className="search"
//         />
//              <Typography
//                     variant="h5"
//                     sx={{
//                       position: "absolute",
//                       right: "10%",
//                       margin: "-18px",
//                       pt: { xs: "5px", md: "0" },
//                     }}
//                     style={{margin: "-58px 10px"}}
//                   >
//                     <img
//                       src={MervIcon}
//                       className="log-merv-wallet"
//                       style={{
//                         height: "22px",
//                         width: "22px",
//                         position: "relative",
//                         top: "5px",
//                       }}
//                     />{" "}
//                     {coins?.data?.data} <br />
//                     <img
//                       src={yellowt}
//                       className="log-merv-wallet"
//                       style={{
//                         height: "22px",
//                         width: "22px",
//                         position: "relative",
//                         top: "5px",
//                         right: "5px",
//                       }}
//                     />
//                     {/* {dataWallet?.data?.data?.walletAmount} */}
//                     <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
//                   </Typography>
//       </div>
      
//       <div className="table-t1">
//         <table style={{borderCollapse:"collapse", width:"100%"}}>
//           <tr className="table-th">
//             <th>Lottery Name</th>
//             <th>Ticket Number</th>
//             <th>Win Amount</th>
//             <th>Claim</th>
//             {/* <th>Claim Status</th> */}
//             <th>Redeem</th>
//             <th>Win Status</th>
//             <th>Ticket Price</th>
//             {/* <th>Free Ticket Amt</th> */}
//             <th>CreatedAt</th>
//             <th>Claim Remark</th>
//           </tr>
//           {data.map((item, i) => {
//             return (
//               <tr key={i} className="table-th2">
//                 <td>{item?.LotteryName}</td>
//                 <td>{item?.ticketNumber}</td>
//                 <td>{item?.winAmount}</td>
//                 {Number(item?.winAmount)>0 && item?.claimStatus!=="Approved" && item?.claimStatus!=="Pending"? 
//                ( <td>
//                 {item?.claimStatus==="Rejected" ?
//                   <button
//                     onClick={() =>
//                       winAmountClaim(item?.transactionId, item?.winAmount)
//                     }
//                     className="claim-reject-btn"
//                   >
//                     Rejected
//                   </button>
//                   :
//                   <button
//                   onClick={() =>
//                     winAmountClaim(item?.transactionId, item?.winAmount)
//                   }
//                   className="claim-btn"
//                 >
//                  Claim
//                 </button>
//           }
//                 </td>)
//           :(
//             <td>
      
              
//               {item?.claimStatus!=="Pending" ?   item?.claimStatus!=="Approved" ? 
//                     <button
//                     onClick={() =>
//                       winAmountClaim(item?.transactionId, item?.winAmount)
//                     }
//                     className="claim-btn-disabled"
//                     disabled
//                   >
//               Claim
//               </button>
//               :
//               <button
//               className="claim-btn-approve-disabled"
//               disabled
//               >
//                 Approved
//                 </button> 
//               :
//               <button
//               className="claim-btn-process-disabled"
//               disabled
//               >
//               Processing
//               </button>
//               }
//           </td>
//           )
//             }
//                 {/* <td>{item.claimStatus}</td> */}
//                 {Number(item?.surpriceAmt)>0?
//                 (<td>
//                   <button
//                     onClick={() =>
//                       redeemPrize(item?.transactionId, item?.surpriceAmt)
//                     }
//                     className="redeem-btn"
//                     disabled={showLoader}
//                   >
//                    {showLoader? <div>Redeem</div>:<>Redeem</>}
//                   </button>
//                 </td>) :(
//                   <td>
//                    <button
//                    onClick={() =>
//                      redeemPrize(item?.transactionId, item?.surpriceAmt)
//                    }
//                    className="redeem-btn-disabled"
//                    disabled
//                  >
//                    Redeem
//                  </button>
//                  </td>
//                 )
//             } 
//                 <td>{item?.lotteryStatus}</td>
//                 <td>{item?.amount}</td>
//                 {/* <td>{item?.surpriceAmt}</td> */}
//                 <td>{item?.createdDate}</td>
//                 <td>{item?.claimRemark}</td>
//               </tr>
//             );
//           })}
//         </table>
//       </div>

//       {value == "" ? (
//         <Pagination
//           pageSize={countPerPage}
//           onChange={updatePage}
//           current={currentPage}
//           total={totalRecords}
//         />
//       ) : (
//         ""
//       )}
//     </>
//   );
// };
// export default MyWinningList;



import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./myWinning.css";
import Swal from "sweetalert2";
import { Button, Typography } from "@mui/material";
import { Opacity } from "@material-ui/icons";
import MervIcon from "@/assets/mervIcon.svg";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from 'react-currency-format';
const MyWinningList = () => {
  const countPerPage = 10;
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [data, setData] = useState([
    {
      LotteryName: "",
      ticketNumber: "",
      winAmount: 0,
      claimStatus: "",
      lotteryStatus: "",
      amount: "",
      surpriceAmt: 0,
      createdDate: "",
      createdTime: "",
      claimRemark: "",
      transactionId: "",
    },
  ]);
  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const [isLoading, setIsLoading] = useState(false);

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );
  const column = Object.keys(data[0]);
const [showLoader,setShowLoader]=useState(false)
  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  useEffect(() => {
    getData(value, 1);
  }, [value]);
  console.log("value win", value);
  console.log("data Winning", data);
  const redeemPrize = async (id: any, amount: any) => {
    try {
      let token = storage.getToken();
      let payload = {
        amount: amount,
      };
      setShowLoader(true);
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/redeemSupriceInWallet/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      Swal.fire({
        background: "black",
        color: "#ffff",
        icon: "success",
        title: "Success!",
        text: "The prize of a free ticket is added to your wallet",
      }).then((res) => {
        setShowLoader(false);
        window.location.reload();
      });
    } catch (err) {
           setShowLoader(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        title: "Error!",
        text: "Oops something went wrong. Please try again later.",
      }).then((res) => {
        window.location.reload();
      });
    }
  };

  const winAmountClaim = async (id: any, winAmount: any) => {
    try {
      let token = storage.getToken();
      let payload = {
        transactionId: id,
        amount: winAmount,
      };
      setIsLoading(true);
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/winAmountClaim`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      Swal.fire({
        background: "black",
        color: "#ffff",
        icon: "success",
        title: "Success!",
        text: res?.data?.message,
      });
      setIsLoading(false);
    } catch (err: any) {
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: err?.response.data?.message,
      });
    }
  };
  const getData = async (value, p) => {
    let token = storage.getToken();
    let pageLimit;
    pageLimit = value != "" ? totalRecords : 10;
    const payable = {
      page: p,
      limit: pageLimit,
    };
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/myWinning?search=${value}`,
        payable,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setTotalRecords(res.data.winningPageCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
    getData(value, p);
  };
  console.log("?search=", value);
  console.log("Redeem Loader",showLoader );
  return (
    <>
      <div style={{overflow:"auto"}}>
        <input
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="search"
        />
             <Typography
                    variant="h5"
                    sx={{
                      position: "absolute",
                      right: "10%",
                      margin: "-18px",
                      pt: { xs: "5px", md: "0" },
                    }}
                    style={{margin: "-58px 10px"}}
                  >
                    <img
                      src={MervIcon}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                      }}
                    />{" "}
                    {coins?.data?.data} <br />
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                        right: "5px",
                      }}
                    />
                    {/* {dataWallet?.data?.data?.walletAmount} */}
                    <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
                  </Typography>
      </div>
      
      <div className="table-t1">
        <table style={{borderCollapse:"collapse", width:"100%"}}>
          <tr className="table-th">
            <th>Lottery Name</th>
            <th>Ticket Number</th>
            <th>Win Amount</th>
            <th>Claim</th>
            {/* <th>Claim Status</th> */}
            <th>Redeem</th>
            <th>Win Status</th>
            <th>Ticket Price</th>
            {/* <th>Free Ticket Amt</th> */}
            <th>CreatedAt</th>
            <th>Claim Remark</th>
          </tr>
          {data.map((item, i) => {
            return (
              <tr key={i} className="table-th2">
                <td>{item?.LotteryName}</td>
                <td>{item?.ticketNumber}</td>
                <td>{item?.winAmount}</td>
                {Number(item?.winAmount)>0 && item?.claimStatus!=="Approved" && item?.claimStatus!=="Pending"? 
               ( <td>
                {item?.claimStatus==="Rejected" ?
                  <button
                    onClick={() =>
                      winAmountClaim(item?.transactionId, item?.winAmount)
                    }
                    className="claim-reject-btn"
                  >
                    Rejected
                  </button>
                  :
                  <button
                  onClick={() =>
                    winAmountClaim(item?.transactionId, item?.winAmount)
                  }
                  className="claim-btn"
                >
                 Claim
                </button>
          }
                </td>)
          :(
            <td>
      
              
              {item?.claimStatus!=="Pending" ?   item?.claimStatus!=="Approved" ? 
                    <button
                    onClick={() =>
                      winAmountClaim(item?.transactionId, item?.winAmount)
                    }
                    className="claim-btn-disabled"
                    disabled
                  >
              Claim
              </button>
              :
              <button
              className="claim-btn-approve-disabled"
              disabled
              >
                Approved
                </button> 
              :
              <button
              className="claim-btn-process-disabled"
              disabled
              >
              Processing
              </button>
              }
          </td>
          )
            }
                {/* <td>{item.claimStatus}</td> */}
                {Number(item?.surpriceAmt)>0?
                (<td>
                  <button
                    onClick={() =>
                      redeemPrize(item?.transactionId, item?.surpriceAmt)
                    }
                    className="redeem-btn"
                    disabled={showLoader}
                  >
                   {showLoader? <div>Redeem</div>:<>Redeem</>}
                  </button>
                </td>) :(
                  <td>
                   <button
                   onClick={() =>
                     redeemPrize(item?.transactionId, item?.surpriceAmt)
                   }
                   className="redeem-btn-disabled"
                   disabled
                 >
                   Redeem
                 </button>
                 </td>
                )
            } 
                <td>{item?.lotteryStatus}</td>
                <td>{item?.amount}</td>
                {/* <td>{item?.surpriceAmt}</td> */}
                <td>{item?.createdDate}</td>
                <td>{item?.claimRemark}</td>
              </tr>
            );
          })}
        </table>
      </div>

      {value == "" ? (
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={totalRecords}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default MyWinningList;