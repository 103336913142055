import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { LiveLotteries } from '../types';
import { ResponseType } from 'axios';


// Main API Response Interface
export interface LotteryApiResponse {
  message: string;
  data: Array<Lottery>;
  page: number;
  limit: number;
  totalPages: number;
  totalRecords: number;
}

// Lottery Data Interface
export interface Lottery {
  _id: string;
  lotteryName: string;
  ticketPrice: number;
  numOfTicketOnSale: number;
  numOfTicketSold: number;
  startDate: string; // ISO Date string
  startTime: string; // ISO Time string
  endDate: string; // ISO Date string
  endTime: string; // ISO Time string
  lotteryDrawDate: string; // ISO Date string
  lotteryDrawTime: string; // ISO Time string
  totalDraw: number;
  drawPrize: number[];
  instruction: string;
  maxRpmLotteryWheel: number;
  decelarationOfLotteryWheel: number;
  maxNumOfTickets: number;
  status: boolean;
  image: string;
  lotteryCurrentStatus: string;
  lotteryDrawTktNum: any[]; // Array of ticket numbers (if structured, replace `any` with proper type)
  createdBy: string;
  lotteryEndTimeStamp: number;
  lotteryDrawTimeStamp: number;
  notificationTicketAlloc: string;
  notificationDrawTime: string;
  winEmailNotify: string;
  coinRewardAllocated: string;
  jackpotSelected: boolean;
  createdDate: string; // ISO Date string
  updatedDate: string; // ISO Date string
  soldStatus: boolean;
}


interface UseLotteriesParams {
  page: number;
  limit: number;
}

export const getLottery = async (page: number, limit: number): Promise<LotteryApiResponse | any> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.get<LotteryApiResponse | any>(`/api/v1/admin/liveLotteries?page=${page}&limit=${limit}`,
    {
      headers: {
        'Timezone': timezone, // Add the timezone to the headers
      },
    });
  return response; // Extract the `data` from AxiosResponse
};
//Response.data.upc
type QueryFnType = typeof getLottery;

type UseLotteriesOptions = {
  config?: QueryConfig<QueryFnType>;
  page: number;
  limit: number;
};

// export const useLotteries = ({ config, page, limit }: UseLotteriesOptions) => {
//   return useQuery<ExtractFnReturnType<QueryFnType>>({
//     queryKey: ['data'],
//     queryFn: () => getLottery(page, limit),
//     ...config,
//   });
// };

export const useLotteries = ({ page, limit }: UseLotteriesParams) => {
  return useQuery(['lotteries', page, limit], () => getLottery(page, limit ), {
    keepPreviousData: true, // Optional: Retain data from the previous fetch during transitions
  });
};