import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import lotteryspinner from "@/assets/lotteryspinner.svg";
import RecentActivities from "@/features/Dashboard/components/recentActivities";
import Logo from "@/assets/tableLogo.svg";
import whitet from "@/assets/white-t.png";
import readme from "@/assets/readme.svg";
import { theme } from "@/components/Elements/theme";
import { useNavigate } from "react-router-dom";
import UpcomingLotteryCrousel from "@/features/Homescreen/Components/upcomingLotteryCrousel";
import UpComingLotteryDraws from "@/features/Homescreen/Components/upcomingLotteryDraws";
import WinningHistory from "@/features/Homescreen/Components/winningHistory";
import { useLotteries } from "../api/getDashboardList";
import Countdown from "react-countdown";
import { any } from "zod";
import comingsoon from "@/assets/coming_soon_logo.png";
const useStyles = makeStyles((_theme) => ({
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    // borderTopLeftRadius:"20px",
    // borderBottomLeftRadius:"20px",
    borderRadius: "20px",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "110px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
    },
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "7px",
    },
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  logo: {
    width: 50,
  },
}));

export const Timer = ({ dataRecent }) => {

  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <>
      {dataRecent?.firstupComRecords?.length  ? (
        <Grid container spacing={2}>
          <Grid item md={5} xs={5}>
            <Box>
              <div style={{ position: "relative", width: "fit-content" }}>
                <img
                  className={classes.innerGridFirst}
                  alt="lottery-name"
                  // eslint-disable-next-line no-template-curly-in-string
                  src={
                    dataRecent?.firstupComRecords[0]?.image !== undefined
                      ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataRecent?.firstupComRecords[0]?.image}`
                      : lotteryspinner
                  }
                ></img>
                {/* <Typography variant="body1" align='center' sx={{
    position:"absolute",
    top: "70%",
    left: "25%",
    background: "black",
    padding: "13px",
    borderRadius: "32px"
    }}>
       {dataRecent?.firstupComRecords[0]?.lotteryName}</Typography> */}
              </div>
            </Box>
          </Grid>
          <Grid item md={6} xs={6} className={classes.innerGridSecond}>
            {/* {dataRecent?.firstupComRecords[0]?.numOfTicketSold >= 999 ? (
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <Typography
                variant="caption"
                align="center"
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  fontSize: { xs: "14px", md: "19px" },
                }}
              >
                {dataRecent?.firstupComRecords[0]?.lotteryName}
              </Typography>
            </Grid>
            {/* <Grid item  xs={12} md={6} justifyContent={"flex-start"}>
                                            <Typography variant="caption" align='left' sx={{color: "rgba(255, 255, 255, 0.8)"}}>Available Ticket: {dataRecent?.firstupComRecords[0]?.numOfTicketOnSale - dataRecent?.firstupComRecords[0]?.numOfTicketSold || 0}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} justifyContent={{xs:"flex-start",md:"flex-end"}} sx={{display:"flex"}}>
                                            <Typography variant="caption" align='right' sx={{color: "rgba(255, 255, 255, 0.8)"}}>Ticket Sold: {dataRecent?.firstupComRecords[0]?.numOfTicketSold? dataRecent?.firstupComRecords[0]?.numOfTicketSold:0}</Typography>
                                        </Grid> */}
            {/* </Grid>
        ) : ""} */}

            {Number(dataRecent?.firstupComRecords[0]?.numOfTicketOnSale) -
              Number(dataRecent?.firstupComRecords[0]?.numOfTicketSold) <
            1000 ? (
              // <Grid container>
              //   <Grid
              //     item
              //     xs={12}
              //     md={12}
              //     justifyContent={"center"}
              //     textAlign={"center"}
              //   >
              //     <Typography
              //       variant="caption"
              //       align="center"
              //       sx={{
              //         color: "rgba(255, 255, 255, 0.8)",
              //         fontSize: { xs: "14px", md: "19px" },
              //       }}
              //     >
              //       {dataRecent?.firstupComRecords[0]?.lotteryName}
              //     </Typography>
              //   </Grid>
              <Grid container>
                <Grid item xs={12} justifyContent={"flex-center"}>
                  <Typography
                    // variant="caption"
                    variant="h3"
                    // align="center"
                    sx={{ color: "rgba(255, 255, 255, 0.8)", textAlign: "center" }}
                  >
                    Available Ticket:{" "}
                    {dataRecent?.firstupComRecords[0]?.numOfTicketOnSale -
                      dataRecent?.firstupComRecords[0]?.numOfTicketSold || 0}
                  </Typography>
                </Grid>
                {/* <Grid
              item
              xs={12}
              md={6}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
              sx={{ display: "flex" }}
            >
              <Typography
                variant="caption"
                style={{fontWeight: "700"}}
                // variant="h5"
                align="right"
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
              >
                Ticket Sold:{" "}
                {dataRecent?.firstupComRecords[0]?.numOfTicketSold
                  ? dataRecent?.firstupComRecords[0]?.numOfTicketSold
                  : 0}
              </Typography>
            </Grid> */}
              </Grid>
            ) : (
              ""
            )}
            <Grid container>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div></div>
                  <Grid container sx={{ mt: { md: "21px", xs: "5px" } }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "x-large",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{
                          textTransform: "uppercase",
                          color: "white",
                          fontSize: "x-large",
                        }}
                      >
                        {dataRecent?.firstupComRecords[0]?.lotteryName
                          ? dataRecent?.firstupComRecords[0]?.lotteryName
                          : ""}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        sx={{ color: "white", fontSize: "medium" }}
                      >
                        <span style={{ fontSize: "medium" }}>
                          {" "}
                          Ticket Price :{" "}
                        </span>
                        <img
                          style={{
                            height: "auto",
                            width: "20px",
                            marginRight: "2px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          src={whitet}
                          alt="currency"
                        />{" "}
                        {dataRecent?.firstupComRecords[0]?.ticketPrice
                          ? dataRecent?.firstupComRecords[0]?.ticketPrice
                          : " "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: { md: "21px", xs: "5px" } }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  sx={{ textTransform: "uppercase", color: "#FFBC00" }}
                >
                  End date :{" "}
                  {dataRecent?.firstupComRecords[0]?.lotteryDrawDate
                    ? new Date(
                        dataRecent?.firstupComRecords[0]?.lotteryDrawDate
                      )
                        ?.toISOString()
                        .split("T")[0]
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  variant="caption"
                  align="center"
                  sx={{ textTransform: "uppercase", color: "#FFBC00" }}
                >
                  End time :{" "}
                  {dataRecent?.firstupComRecords[0]?.lotteryDrawDate
                    ? new Date(
                        dataRecent?.firstupComRecords[0]?.lotteryDrawDate
                      )
                        ?.toISOString()
                        .split("T")[1]
                        .substring(0, 5) + "UTC"
                    : " "}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  align="center"
                  sx={{ fontSize: "45px", fontWeight: "700" }}
                >
                  {dataRecent?.firstupComRecords[0]?.endDate ? (
                    <Countdown
                      date={
                        new Date(
                          `${dataRecent?.firstupComRecords[0]?.endDate}T${dataRecent?.firstupComRecords[0]?.endTime}`
                        )
                      }
                    />
                  ) : (
                    "00:00:00"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                {dataRecent?.firstupComRecords[0]?._id ? (
                  <Button
                    sx={{
                      marginBottom: "19px !important",
                      backgroundColor: "#3C37FF",
                      color: "#FFFF",
                      borderRadius: "19px",
                      "&:hover":{
                        backgroundColor: "#5853f7",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/app/Lotteries/${dataRecent?.firstupComRecords[0]?._id}`
                      )
                    }
                  >
                    Buy Tickets{" "}
                    <img src={readme} style={{ marginLeft: "10px" }} />
                  </Button>
                ) : (
                  <Button
                    sx={{
                      marginBottom: "19px !important",
                      backgroundColor: "#3C37FF",
                      color: "#FFFF",
                      borderRadius: "19px",
                      "&:hover":{
                        backgroundColor: "#5853f7",
                      },
                    }}
                  >
                    Buy Tickets{" "}
                    <img src={readme} style={{ marginLeft: "10px" }} />
                  </Button>
                )}
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            border: "1px solid",
            textAlign: "center",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            // src={comingsoon}
            src="/Comp_1.gif"
            alt="Coming Soon"
            style={{
              height: "230px",
              width: "220px",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Timer;
